<template>
  <component :is="categoryData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="categoryData === undefined">
      <h4 class="alert-heading">
        Erreur de récupération des données de catégories
      </h4>
      <div class="alert-body">
        Pas de Catégorie trouvé sur cet identifiant. Vérifiez
        <b-link class="alert-link" :to="{ name: 'apps-item-categories-list' }">
          la liste des catégories
        </b-link>
        pour d'autres catégories.
      </div>
    </b-alert>

    <b-tabs v-if="categoryData" pills>
      <!-- Tab: Général -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Création Catégorie</span>
        </template>
        <handle-item-category-tab-general
          class="mt-2 pt-75"
          :category-data.sync="categoryData"
          :available-categories-list="parentsList"
          @data-handled="$router.push({ name: 'apps-item-categories-list' })"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
/* eslint-disable */
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import HandleItemCategoryTabGeneral from "./HandleItemCategoryTabGeneral.vue";
import useItemCateogriesList from "../item-categories-list/useItemCategoriesList";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "HandleItemCateogries",
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    HandleItemCategoryTabGeneral,
  },
  setup() {
    const blankCategoryData = {
      name: "",
      description: "",
      parent: null,
    };
    const parentsList = ref([]);

    const categoryData = ref(JSON.parse(JSON.stringify(blankCategoryData)));
    const fetchCategory = () => {
      store
        .dispatch("itemCategory/fetchCategory", {
          id: router.currentRoute.params.id,
        })
        .then((category) => {
          categoryData.value = category;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            categoryData.value = undefined;
          }
        });
    };

    onMounted(() => {
      if (router.currentRoute.params.id) {
        fetchCategory();
      }
      getParents();
    });

    const filterParentCategories = (list) => {
      if(list === undefined) return [];
      return list.filter((c) => c.is_parent);
    }

    const getParents = async () => {
      const categories = await store.dispatch("itemCategory/allCategories");
      if (categories !== undefined) {
        parentsList.value = filterParentCategories(categories)
      }
    };

    return {
      categoryData,
      parentsList,
    };
  },
};
</script>
